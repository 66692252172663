export const StorageKeys = {
  ACCESS_TOKEN: "accessToken",
  ID_TOKEN: "idToken",
  LOCAL_CRUMBS: "local-crumbs",
  ACTIVE_TAB: "activeTab",
  SEARCH_FORM_DATA: "search-form-data",
  SEARCH_FORM_PARAMS: "search-form-params",
  SEARCH_LISTING_DATA: "search-listing-data",
  SEARCH_LISTING_PARAMS: "search-listing-params",
  CLIENT_ID: "client-id",
  PROFILE_DATA: "profile-data",
  serviceLocalStorage: "serviceLocalStorage",
  SEARCH_FORM_TYPE: "search-form-type",
  SEARCH_FORM_CITY: "search-form-city",
  INQUIRY_ITEM: "inquiry-item",
  CART_ITEM: "cart-item"
};

export const StorageServiceObject = {
  warehouse: false,
  transport: false
};

export const searchFormType = {
  findByWarehouseForm: "findByWarehouseForm",
  findByTransportForm: "findByTransportForm"
};
