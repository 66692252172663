import React from "react";
import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import { Box, Grid } from "@mui/material";
import SimpleErp from "../../assets/SimpleERP.webp";
import BookTruck from "../../assets/BookTruck.webp";
import BookWarehouse from "../../assets/BookWarehouse.webp";
import EwayBill from "../../assets/EwayBills.webp";
import GetCredit from "../../assets/GetCredit.webp";
import OfferCardItem from "./OfferCardItem";
import appRoutes from "../../constants/appRoutes";
import { isMobile } from "react-device-detect";

const offerings = [
  {
    label: home_labels.simpleErpTitle,
    subtext: home_labels.simpleErpText,
    image: SimpleErp,
    id: "SimpleErp",
    to: appRoutes.erp
  },
  {
    label: home_labels.bookTruckTitle,
    subtext: home_labels.bookTruckText,
    image: BookTruck,
    id: "BookTruck",
    to: appRoutes.explore.main
  },
  {
    label: home_labels.bookWarehouseTitle,
    subtext: home_labels.bookWarehouseText,
    image: BookWarehouse,
    id: "BookWarehouse",
    to: appRoutes.explore.main
  },
  {
    label: home_labels.ewayBillTitle,
    subtext: isMobile
      ? "Generate E-waybills and auto assign to our trucks along with packing note"
      : home_labels.ewayBillText,
    image: EwayBill,
    id: "EwayBill",
    to: appRoutes.ewaybill
  },
  {
    label: home_labels.getCreditTitle,
    subtext: isMobile
      ? "Get cash from your unpaid invoices through our partnered NBFCs."
      : home_labels.getCreditText,
    image: GetCredit,
    id: "GetCredit",
    to: appRoutes.credit
  }
];

const OfferSection = () => {
  return (
    <Box
      id="offer-section"
      display="flex"
      flexDirection="column"
      textAlign="center"
      sx={{
        background: { xs: "none", md: "#F7F8FA" },
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: "0 20px",
          md: "5px 65px"
        },
        gap: { xs: 0, md: 2 }
      }}
    >
      <SectionHeader title={home_labels.offerText} />
      <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
        {offerings.map((item, index) => (
          <>
            {index === 3 && <Grid item xs={2}></Grid>}
            <Grid item xs={4}>
              <OfferCardItem index={index} {...item} />
            </Grid>
            {index === 4 && <Grid item xs={2}></Grid>}
          </>
        ))}
      </Grid>
      <Box
        sx={{
          mt: "24px",
          width: "100%",
          display: {
            xs: "block",
            md: "none"
          },
          padding: "0 2px"
        }}
      >
        <Grid container spacing="4px">
          {offerings.map((item, index) => (
            <Grid item xs={index === 4 ? 12 : 6}>
              <OfferCardItem index={index} {...item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default OfferSection;
