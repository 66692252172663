import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { ValueLabelText } from "../../../purchase/components/Purchase/MobilePOTable";
import { RemoveCircleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";

export const ItemDetailsCard = ({ row, remove }) => {
  const onRemoveClick = (index) => {
    remove(index);
  };

  return row?.brandName ? (
    <Grid container spacing={1}>
      <Grid
        item
        xs={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <Box display={"flex"} gap={1} alignItems={"center"}>
          {row?.image?.thumbUrl && (
            <img
              src={row?.image?.thumbUrl}
              alt={row?.brandName ?? "--"}
              style={{
                objectFit: "contain",
                maxWidth: "50px",
                maxHeight: "50px"
              }}
            />
          )}
          <Typography
            fontSize={12}
            fontWeight={600}
            color={"primary"}
            component={Link}
          >
            {row?.skuCode}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <IconButton onClick={() => onRemoveClick(row?.index)}>
          <RemoveCircleOutline color="error" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={2}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Qty"} value={row.quantity} />
      </Grid>
      <Grid
        item
        xs={6}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Name"} value={row.brandName} onLeft />
      </Grid>
      <Grid
        item
        xs={2}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.disabled"}
          pl={1}
        >
          {row?.unit?.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"flex-start"}
      >
        <ValueLabelText label={"Rate"} value={row.rate} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <ValueLabelText label={"HSN"} value={row.hsn} onLeft isMandotary />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ValueLabelText label={`CGST(${row.gstRegime})%`} value={row.cgst} />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText label={`SGST(${row.gstRegime})%`} value={row.sgst} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"left"}
        alignItems={"center"}
      >
        <ValueLabelText
          label={"Taxable Value"}
          value={row.taxable}
          onLeft
          isMandotary
        />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Cess Advol"} value={row.advol} />
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        justifyContent={"right"}
        alignItems={"center"}
      >
        <ValueLabelText label={"Cess Nonadvol"} value={row.Nonadval} />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid
        item
        xs={5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        {row?.item}
      </Grid>
      <Grid
        item
        xs={7}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
      >
        <IconButton onClick={() => onRemoveClick(row?.index)}>
          <RemoveCircleOutline color="error" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.disabled"}
        >
          {"PCs"}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
      >
        {row?.rate}
      </Grid>
      <Grid item xs={4}>
        {row?.quantity}
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight={"14.52px"}
          color={"text.disabled"}
        >
          {`HSN `}
          <sup style={{ color: "#F05454" }}>*</sup>
          {`: ${row?.hsn || ""}`}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {row.advol}
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"right"}
      >
        {row.Nonadval}
      </Grid>
    </Grid>
  );
};
