import { Box, Typography } from "@mui/material";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import { contactUsFormKeys } from "../../constants/formKeys";
import { home_labels, labels } from "../../constants";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { StyledHeaderText } from "./SaaS";
import styled from "@emotion/styled";
import SolutionCards from "../components/SolutionsSection";
import MarketplaceSection from "../components/MarketplaceSection";
import { isMobile } from "react-device-detect";

export const StyledText = styled(Typography)((props) => ({
  fontSize: props.isMobile ? "14px" : "22px",
  fontWeight: 400,
  lineHeight: props.isMobile ? "21px" : "33px",
  letterSpacing: "-0.01em",
  textAlign: "justify",
  color: "#607088"
}));

const Marketplace = () => {
  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });

  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      {/* Mobile view */}
      {/* <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          padding: "30px 25px 0px 25px",
          gap: 2
        }}
      >
        <StyledHeaderText
          color={"#F9A90E"}
          component="h1"
          isMobile={true}
          textAlign={"center"}
        >
          {`${labels.marketplaceHeaderText1} `}
          <span style={{ color: "#2773FF" }}>
            {labels.marketplaceHeaderText2}
          </span>
        </StyledHeaderText>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              src={MarketplaceChart}
              alt={"SaaS enabled Marketplace"}
              width={"100%"}
              height={"100%"}
              style={{
                objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <StyledText isMobile>
              A multi tenant cloud based marketplace of{" "}
              <span style={{ color: "#F9A90E" }}>Warehouses and Trucks</span> .
            </StyledText>
            <StyledText isMobile>
              A <span style={{ color: "#F9A90E" }}>One Stop Solution</span> to
              all your Logistics needs.
            </StyledText>
            <StyledText isMobile>
              Solve your{" "}
              <span style={{ color: "#F9A90E" }}>
                fulfillment and distribution
              </span>{" "}
              problems without owning or renting any assets.
            </StyledText>
            <StyledText isMobile>
              Reduce your operational costs by using our{" "}
              <span style={{ color: "#F9A90E" }}>pay-per-use</span> logistics
              solutions.
            </StyledText>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box display={"flex"} justifyContent={"center"}>
            <iframe
              loading="lazy"
              width="320px"
              height="240px"
              title="Saas-Marketplace"
              src="https://www.youtube.com/embed/avXCghyXXU8?playlist=avXCghyXXU8&loop=1"
            ></iframe>
          </Box>
          <StyledText isMobile>
            Our portal enables customers to send enquiries and receive
            competitive bids directly from various partners. Customers can
            prepare a detailed packing note and track each package. Our Request
            For Quote system enables customers to negotiate with multiple
            vendors and get services at cheapest prices. Please go through our
            Product-Explainer-Video to know about various features of Warex.{" "}
          </StyledText>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box> */}
      {/* Web view */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "10px 25px 0px 25px", md: "30px 70px 0px 80px" },
            gap: { xs: 2, md: 4 }
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={0.7}
          >
            <StyledHeaderText
              color={"#F9A90E"}
              component="h1"
              isMobile={isMobile}
            >
              {`${labels.marketplaceHeaderText1} `}
              <span style={{ color: "#2773FF" }}>
                {labels.marketplaceHeaderText2}
              </span>
            </StyledHeaderText>
            <Typography
              sx={{
                fontSize: { xs: 8, md: 16 },
                lineHeight: { xs: "12px", md: "24px" },
                fontWeight: 400,
                color: "text.secondary"
              }}
            >
              {labels.marketplaceSubHeaderText}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 4 }
            }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <ul
                style={{
                  listStyleType: "disc",
                  color: "#607088",
                  fontSize: isMobile ? "14px" : "22px",
                  margin: 0,
                  paddingInlineStart: "22px",
                  lineHeight: 2
                }}
              >
                <li>
                  <StyledText isMobile={isMobile}>
                    We offer distribution through our marketplace of partnered{" "}
                    <span style={{ color: "#F9A90E" }}>
                      Warehouses and Transporters
                    </span>{" "}
                    .
                  </StyledText>
                </li>
                <li>
                  <StyledText isMobile={isMobile}>
                    A{" "}
                    <span style={{ color: "#F9A90E" }}>One Stop Solution</span>{" "}
                    to all your supply chain needs.
                  </StyledText>
                </li>
                <li>
                  <StyledText isMobile={isMobile}>
                    Solve your{" "}
                    <span style={{ color: "#F9A90E" }}>
                      fulfillment and distribution
                    </span>{" "}
                    problems without owning or renting any logistics assets.
                  </StyledText>
                </li>
                <li>
                  <StyledText isMobile={isMobile}>
                    Reduce your operational costs by using our{" "}
                    <span style={{ color: "#F9A90E" }}>pay-per-use</span>{" "}
                    logistics solutions.
                  </StyledText>
                </li>
              </ul>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <iframe
                loading="lazy"
                width={isMobile ? "320px" : "450px"}
                height={isMobile ? "240px" : "250px"}
                title="Saas-Marketplace"
                src="https://www.youtube.com/embed/avXCghyXXU8?playlist=avXCghyXXU8&loop=1"
              ></iframe>
            </Box>
          </Box>
        </Box>
        <SolutionCards />
        <Box
          display={"flex"}
          gap={2}
          sx={{
            padding: { xs: "30px 25px 0px 25px", md: "30px 70px 0px 80px" },
            gap: { xs: 2, md: 4 }
          }}
        >
          <StyledText isMobile={isMobile}>
            Our portal enables customers to send enquiries and receive
            competitive bids directly from various partners. Customers can
            prepare a detailed packing note and track each package. Our Request
            For Quote system enables customers to negotiate with multiple
            vendors and get services at cheapest prices. Please go through our
            Product-Explainer-Video to know about various features of Warex.{" "}
          </StyledText>
        </Box>
        <MarketplaceSection />
        <Box
          sx={{
            padding: { xs: "30px 25px 0px 25px", md: "30px 70px 0px 80px" }
          }}
        >
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: { xs: "12px", md: "22px" },
              lineHeight: { xs: "18px", md: "33px" },
              letterSpacing: "-1%",
              textAlign: "justify"
            }}
            fontWeight={700}
          >
            {labels.contactUsToBookDemoText}
          </Typography>
        </Box>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};
export default Marketplace;
