import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Storage from "../../shared/utils/Storage";
import { searchFormType, StorageKeys } from "../../constants/storage-keys";

const OfferCardItem = (props) => {
  const navigate = useNavigate();
  const { id, to, image, label, subtext } = props;
  return (
    <Card
      id={id}
      elevation={0}
      onClick={() => {
        props?.index === 1
          ? Storage.setItem(
              StorageKeys.SEARCH_FORM_TYPE,
              searchFormType.findByTransportForm
            )
          : props?.index === 2
          ? Storage.setItem(
              StorageKeys.SEARCH_FORM_TYPE,
              searchFormType.findByWarehouseForm
            )
          : console.log("");
        navigate(to);
      }}
      sx={{
        cursor: "pointer",
        position: "relative",
        width: "100%",
        height: "100%",
        background: { xs: "#F7F8FA", md: "none" },
        boxShadow: "none",
        textAlign: "center",
        padding: { xs: "20px 8px", md: 2 },
        "&:hover .hover-image": {
          transform: "translateY(-8px)",
          boxShadow: "6px 6px 6px rgba(249, 169, 14, 0.5)"
        }
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "end"
        }}
      >
        <Grid
          className="hover-image"
          item
          xs={12}
          display={"inline-flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            background: "none",
            borderRadius: "100%",
            overflow: "hidden",
            transition: "transform 0.3s, box-shadow 0.3s",
            mb: {
              xs: "7px",
              md: "25px"
            }
          }}
        >
          <img
            loading="lazy"
            src={image}
            alt={label}
            width={isMobile ? "60px" : "180px"}
            height={isMobile ? "60px" : "180px"}
            style={{
              objectFit: "contain" // Ensures the entire image fits within the box
            }}
          />
        </Grid>
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <Typography
            color={"#0F2744"}
            sx={{
              maxWidth: {
                xs: props?.index === 1 ? "44px" : "124px",
                md: props?.index === 1 ? "140px" : "155px"
              },
              fontSize: {
                xs: 16,
                md: 28
              },
              lineHeight: {
                xs: "19.36px",
                md: "34px"
              },
              fontWeight: {
                xs: 500,
                md: 500
              },
              marginTop: {
                xs: 0.8,
                md: 0
              }
            }}
            letterSpacing={"0.002em"}
            gutterBottom
          >
            {label}
          </Typography>
          <Typography
            sx={{
              maxWidth: {
                xs: "144px",
                md: "299px"
              },
              height: { xs: "100%" },
              color: "#607088",
              fontSize: {
                xs: 10,
                md: 16
              },
              lineHeight: {
                xs: "18px",
                md: "19.36px"
              },
              fontWeight: {
                xs: 500,
                md: 500
              },
              marginY: {
                xs: 0.2,
                md: 2
              },
              mt: { xs: props?.index === 3 ? 2.4 : 0, md: 0 }
            }}
            letterSpacing={"0.002em"}
          >
            {subtext}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OfferCardItem;
