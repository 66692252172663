import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { home_labels, labels } from "../../constants";
import { contactUsFormKeys } from "../../constants/formKeys";
import ContactUsSection from "../components/ContactUsSection";
import Footer from "../components/Footer";
import EwaybillDashboard from "../../assets/ewaybill_dashboard.webp";
import EwaybillUpdate from "../../assets/Ewaybill_update.webp";
import EwaybillSideDrawer from "../../assets/ewaybill_sideDrawer.webp";

const ParaText = ({ children }) => {
  return (
    <Typography
      sx={{
        color: "text.secondary",
        fontSize: { xs: "12px", md: "22px" },
        lineHeight: { xs: "18px", md: "33px" },
        letterSpacing: "-1%",
        textAlign: "justify"
      }}
      fontWeight={400}
    >
      {children}
    </Typography>
  );
};

const EwayBill = () => {
  const { control } = useForm({
    defaultValues: {
      [contactUsFormKeys.name]: "",
      [contactUsFormKeys.number]: "",
      [contactUsFormKeys.email]: "",
      [contactUsFormKeys.subject]: "",
      [contactUsFormKeys.description]: ""
    }
  });
  useEffect(() => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <>
      {/* web view */}
      <Box
        flexDirection={"column"}
        sx={{
          display: { xs: "none", md: "flex" },
          padding: "30px 70px 0px 105px",
          gap: 2
        }}
      >
        <Typography
          sx={{
            color: "#F9A90E",
            fontSize: { xs: "20px", md: "36px" },
            lineHeight: { xs: "30px", md: "54px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {`${labels.ewaybillHeaderText1} `}
          <span style={{ color: "#2773FF" }}>{labels.ewaybillHeaderText2}</span>
        </Typography>
        <Box display={"flex"} columnGap={8}>
          <Box display={"flex"} flexDirection={"column"}>
            <ParaText>
              <>
                {labels.ewaybillpara1}
                <br />
                <br />
                {labels.ewaybillpara2}
                <br />
                <br />
                {labels.ewaybillpara3}
              </>
            </ParaText>
          </Box>
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              height: "auto",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              loading="lazy"
              src={EwaybillDashboard}
              alt={"Eway-bill dashboard"}
              style={{
                width: "100%",
                height: "100%"
                //objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
        </Box>
        <Box display={"flex"} gap={4} mt={4}>
          <Box
            display={"flex"}
            sx={{
              height: "auto",
              width: "35%",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              loading="lazy"
              src={EwaybillSideDrawer}
              alt={"Eway-bill sideDrawer"}
              style={{
                width: "276px",
                height: "585px",
                objectFit: "contain",
                boxShadow: " 0px 8px 24px 0px #1515161A"
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={4}
            sx={{
              width: "65%",
              height: "auto",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              loading="lazy"
              src={EwaybillUpdate}
              alt={"Eway-bill updates"}
              style={{
                width: "720px",
                height: "400px",
                boxShadow: " 0px 8px 24px 0px #1515161A",
                objectFit: "contain"
              }}
            />
            <ParaText>
              {labels.ewaybillupdate1}
              <br />
              <br />
              {labels.ewaybillupdate2}
            </ParaText>
          </Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify",
            mb: { xs: 0, md: -4 }
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box>
      {/* mobile view */}
      <Box
        flexDirection={"column"}
        sx={{
          display: { xs: "flex", md: "none" },
          padding: "20px 35px 0px 35px",
          gap: 2
        }}
      >
        <Typography
          sx={{
            color: "#F9A90E",
            fontSize: { xs: "20px", md: "36px" },
            lineHeight: { xs: "30px", md: "54px" },
            letterSpacing: "-1%",
            textAlign: "justify"
          }}
          fontWeight={700}
        >
          {`${labels.ewaybillHeaderText1} `}
          <span style={{ color: "#2773FF" }}>{labels.ewaybillHeaderText2}</span>
        </Typography>
        <Box display={"flex"} flexDirection={"column"} gap={2} mt={2}>
          <Box
            display={"flex"}
            sx={{
              width: "100%",
              height: "auto",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <img
              loading="lazy"
              src={EwaybillDashboard}
              alt={"Eway-bill dashboard"}
              style={{
                width: "100%",
                height: "100%"
                //objectFit: "contain" // Ensures the entire image fits within the box
              }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <ParaText>
              <>
                {labels.ewaybillpara1}
                <br />
                <br />
                {labels.ewaybillpara2}
                <br />
                <br />
                {labels.ewaybillpara3}
              </>
            </ParaText>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={4} mt={4} mb={2}>
          <Box
            display={"flex"}
            sx={{
              height: "auto",
              width: "100%",
              position: "relative",
              overflow: "hidden",
              justifyContent: "center"
            }}
          >
            <img
              loading="lazy"
              src={EwaybillSideDrawer}
              alt={"Eway-bill sideDrawer"}
              style={{
                width: "276px",
                height: "585px",
                objectFit: "contain",
                boxShadow: " 0px 8px 24px 0px #1515161A"
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={4}
            justifyContent={"center"}
            sx={{
              width: "100%",
              height: "auto",
              position: "relative",
              overflow: "hidden"
            }}
          >
            <ParaText>
              {labels.ewaybillupdate1}
              <br />
              <br />
              {labels.ewaybillupdate2}
            </ParaText>
            <img
              loading="lazy"
              src={EwaybillUpdate}
              alt={"Eway-bill updates"}
              style={{
                width: "320px",
                height: "179px",
                boxShadow: " 0px 8px 24px 0px #1515161A",
                objectFit: "contain"
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            color: "text.secondary",
            fontSize: { xs: "12px", md: "22px" },
            lineHeight: { xs: "18px", md: "33px" },
            letterSpacing: "-1%",
            textAlign: "justify",
            mb: { xs: 0, md: -4 }
          }}
          fontWeight={700}
        >
          {labels.contactUsToBookDemoText}
        </Typography>
      </Box>
      <ContactUsSection
        control={control}
        title={home_labels.partnerWithUsTitleText}
      />
      <Footer />
    </>
  );
};

export default EwayBill;
