import { isMobile, isTablet } from "react-device-detect";

import AddCustomers from "../../sales/pages/AddCustomers";
import AddVendor from "../../purchase/pages/AddVendor";
import Allocation from "../../sales/pages/Allocation";
import BookOrder from "../../customer/pages/BookOrder";
import CreateInvoice from "../../finance/pages/CreateInvoice";
import CreateOrder from "../../purchase/pages/CreateOrder";
import CreateQuotes from "../../sales/pages/CreateQuotes";
import CreateSalesOrder from "../../sales/pages/CreateSalesOrder";
import Customers from "../../sales/pages/Customers";
import Faqs from "../../home/pages/Faqs";
import Inquiry from "../../customer/pages/Inquiry";
import InvoiceAndDocs from "../../customer/pages/InvoiceAndDocs";
import Invoices from "../../finance/pages/Invoices";
import Landing from "../../home/pages/Landing";
import Listings from "../../customer/pages/Listings";
import MyOrders from "../../customer/pages/MyOrders";
import OrderDetails from "../../customer/components/MyOrders/OrderDetails";
import Orders from "../../customer/pages/Orders";
import PackingNotes from "../../customer/components/MyOrders/PackingNotes";
import Payments from "../../finance/pages/Payments";
import Performance from "../../inventory/pages/Performance";
import Profile from "../../customer/pages/Profile";
import PurchaseOrders from "../../purchase/pages/PurchaseOrders";
import Quotes from "../../sales/pages/Quotes";
import Register from "../../warehouse/pages/Register";
import Retrieve from "../../customer/pages/Retrieve";
import RetrieveOrders from "../../customer/components/MyOrders/RetrieveOrders";
import { Navigate, Route } from "react-router-dom";
import SalesOrders from "../../sales/pages/SalesOrders";
import Settings from "../../settings/pages/Settings";
import Sku from "../../inventory/pages/Sku";
// import TransporterForm from "../../customer/pages/TransporterForm";
import Vendors from "../../purchase/pages/Vendors";
import WarehouseDetailsPage from "../../customer/components/WarehouseDetails";
// import WarehouseSearchForm from "../../customer/pages/WarehouseSearchForm";
import appRoutes from "../../constants/appRoutes";
import StockTransferOrders from "../../purchase/pages/StockTransferOrders";
import StockAdjustMentsOrders from "../../purchase/pages/StockAdjustMentsOrders";
import CreateStockOrder from "../../purchase/pages/CreateStockOrder";
import React, { Fragment } from "react";
import OrderBillingPage from "../../inventory/pages/OrderBillingPage";
import MoverPackersBenguluru from "../../home/pages/MoverPackersBenguluru";
import MoversPackersHyderabad from "../../home/pages/MoversPackersHyderabad";
import Pricing from "../../home/pages/Pricing";
import SaaS from "../../home/pages/SaaS";
import Marketplace from "../../home/pages/Marketplace";
import InventoryManagement from "../../home/pages/InventoryManagement";
import Credit from "../../home/pages/Credit";
import ERP from "../../home/pages/ERP";
import Dashboard from "../../dashboard/pages/Dashboard";
import Inventory from "../../inventory/pages/Inventory";
import ItemLedger from "../../inventory/components/SKU/ItemLedger";
import Warehouse from "../../warehouse/pages/Warehouse";
import AssetsPage from "../../warehouse/pages/AssetsPage";
import MaterialReceivedReports from "../../warehouse/pages/MaterialReceivedReports";
import GatePass from "../../warehouse/pages/GatePass";
import Purchase from "../../purchase/pages/Purchase";
import Sales from "../../sales/pages/Sales";
import Finance from "../../finance/pages/Finance";
import Explore from "../../customer/pages/Explore";
import EWaybills from "../../warehouse/pages/EWaybills";
import EwayBill from "../../home/pages/EwayBill";

// import LazyRoute from "./LazyRoute";

// import { lazy } from "react";

// const Profile = lazy(() => import("../../customer/pages/Profile"));
// const Retrieve = lazy(() => import("../../customer/pages/Retrieve"));
// const RetrieveOrders = lazy(() =>
//   import("../../customer/components/MyOrders/RetrieveOrders")
// );
// const Partner = lazy(() => import("../../home/pages/Partner"));
// const Listings = lazy(() => import("../../customer/pages/Listings"));
// const WarehouseSearchForm = lazy(() =>
//   import("../../customer/pages/WarehouseSearchForm")
// );
// const TransporterForm = lazy(() =>
//   import("../../customer/pages/TransporterForm")
// );
// const WarehouseDetailsPage = lazy(() =>
//   import("../../customer/components/WarehouseDetails")
// );
// const BookOrder = lazy(() => import("../../customer/pages/BookOrder"));
// const Orders = lazy(() => import("../../customer/pages/Orders"));
// const MyOrders = lazy(() => import("../../customer/pages/MyOrders"));
// const OrderDetails = lazy(() =>
//   import("../../customer/components/MyOrders/OrderDetails")
// );
// const Inquiry = lazy(() => import("../../customer/pages/Inquiry"));
// const InvoiceAndDocs = lazy(() =>
//   import("../../customer/pages/InvoiceAndDocs")
// );
// const PackingNotes = lazy(() =>
//   import("../../customer/components/MyOrders/PackingNotes")
// );

const menuWiseRoutes = {
  Dashboard: (
    <Fragment>
      <Route path={appRoutes.dashboard} element={<Dashboard />} />
    </Fragment>
  ),
  MarketPlace: (
    <>
      <Route path={appRoutes.explore.listings} element={<Listings />} />
      <Route path={appRoutes.explore.main} element={<Explore />} />
      <Route path={appRoutes.faqs} element={<Faqs />} />
      <Route path={appRoutes.pricing} element={<Pricing />} />
      <Route path={appRoutes.saas} element={<SaaS />} />
      <Route path={appRoutes.marketplace} element={<Marketplace />} />
      <Route path={appRoutes.credit} element={<Credit />} />
      <Route path={appRoutes.erp} element={<ERP />} />
      <Route
        path={appRoutes.inventoryMangement}
        element={<InventoryManagement />}
      />
      <Route
        path={appRoutes.explore.serviceDetails}
        element={<WarehouseDetailsPage />}
      />
      <Route
        path={appRoutes.completeOrder.serviceBookOrder}
        element={<BookOrder />}
      />
      <Route
        path={appRoutes.completeOrder.warehouseAndTransporterOrder}
        element={<BookOrder />}
      />
      {(isMobile || isTablet) && (
        <Route path={appRoutes.orders.main} element={<Orders />} />
      )}
      <Route
        path={`${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}`}
        element={<MyOrders />}
      />
      <Route
        path={`${appRoutes.orders.main}/${appRoutes.orders.invoicesDocsListView}`}
        element={<InvoiceAndDocs />}
      />
      <Route
        path={`${appRoutes.orders.main}/${appRoutes.orders.retrieveListView}`}
        element={<Retrieve />}
      />
      <Route
        path={`${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}${appRoutes.orders.myOrdersDetailView}`}
        element={<OrderDetails />}
      />
      <Route
        path={`${appRoutes.orders.main}/${appRoutes.orders.myOrdersListView}${appRoutes.orders.myOrdersCreatePackingNotes}`}
        element={<PackingNotes />}
      />
      <Route
        path={appRoutes.orders.myOrdersRetrieveOrders}
        element={<RetrieveOrders />}
      />
      <Route path={appRoutes.inquiry.inquiryListView} element={<Inquiry />} />
    </>
  ),
  Inventory: (
    <>
      {(isMobile || isTablet) && (
        <Route path={appRoutes.inventory.main} element={<Inventory />} />
      )}
      {(isMobile || isTablet) && (
        <Route
          path={`${appRoutes.inventory.main}/${appRoutes.inventory.itemLedger}`}
          element={<ItemLedger />}
        />
      )}
      <Route
        path={`${appRoutes.inventory.main}/${appRoutes.inventory.sku}`}
        element={<Sku />}
      />
      <Route
        path={`${appRoutes.inventory.main}/${appRoutes.inventory.performance}`}
        element={<Performance />}
      />
    </>
  ),
  Warehouse: (
    <>
      {(isMobile || isTablet) && (
        <Route path={appRoutes.warehouse.main} element={<Warehouse />} />
      )}
      <Route
        path={`${appRoutes.warehouse.main}/${appRoutes.warehouse.assets}`}
        element={<AssetsPage />}
      />
      <Route
        path={`${appRoutes.warehouse.main}/${appRoutes.warehouse.register}`}
        element={<Register />}
      />
      <Route
        path={`${appRoutes.warehouse.main}/${appRoutes.warehouse.ewaybills}`}
        element={<EWaybills />}
      />
      <Route
        path={`${appRoutes.warehouse.main}/${appRoutes.warehouse.register}/${appRoutes.warehouse.createMrr}`}
        element={<MaterialReceivedReports />}
      />
      <Route
        path={`${appRoutes.warehouse.main}/${appRoutes.warehouse.register}/${appRoutes.warehouse.gatePass}`}
        element={<GatePass />}
      />
    </>
  ),
  Purchase: (
    <>
      {(isMobile || isTablet) && (
        <Route path={appRoutes.purchase.main} element={<Purchase />} />
      )}
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.vendors}`}
        element={<Vendors />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}`}
        element={<PurchaseOrders />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.stockTransfer}`}
        element={<StockTransferOrders />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.stockAdjustment}`}
        element={<StockAdjustMentsOrders />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.vendors}/${appRoutes.purchase.addVendor}`}
        element={<AddVendor />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.vendors}/:vendorId`}
        element={<AddVendor />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${appRoutes.purchase.createOrder}`}
        element={<CreateOrder />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${appRoutes.purchase.createReturn}/:createReturnId`}
        element={<CreateOrder />}
      />
      <Route
        path={`${appRoutes.purchase.main}/${appRoutes.purchase.purchaseOrders}/${appRoutes.purchase.manageOrder}`}
        element={<CreateOrder />}
      />
      <Route
        path={`${appRoutes.purchase.main}/:stockOrderType/${appRoutes.purchase.createOrder}`}
        element={<CreateStockOrder />}
      />
      <Route
        path={`${appRoutes.purchase.main}/:stockOrderType/${appRoutes.purchase.manageOrder}`}
        element={<CreateStockOrder />}
      />
    </>
  ),
  Sale: (
    <>
      {(isMobile || isTablet) && (
        <Route path={appRoutes.sales.main} element={<Sales />} />
      )}
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.customers}`}
        element={<Customers />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.customers}/:customerId`}
        element={<AddCustomers />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.customers}/${appRoutes.sales.addCustomerDetails}`}
        element={<AddCustomers />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.quotes}`}
        element={<Quotes />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.quotes}/${appRoutes.sales.createQuotes}`}
        element={<CreateQuotes />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.quotes}/:orderId`}
        element={<CreateQuotes />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}`}
        element={<SalesOrders />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${appRoutes.sales.createOrder}`}
        element={<CreateSalesOrder />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${appRoutes.sales.createReturn}/:createReturnId`}
        element={<CreateSalesOrder />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.salesOrders}/${appRoutes.sales.manageOrder}`}
        element={<CreateSalesOrder />}
      />
      <Route
        path={`${appRoutes.sales.main}/${appRoutes.sales.allocation}`}
        element={<Allocation />}
      />
    </>
  ),
  Finance: (
    <>
      {(isMobile || isTablet) && (
        <Route path={appRoutes.finance.main} element={<Finance />} />
      )}
      <Route
        path={`${appRoutes.finance.main}/${appRoutes.finance.invoices}`}
        element={<Invoices />}
      />
      <Route
        path={`${appRoutes.finance.main}/${appRoutes.finance.invoices}/${appRoutes.finance.createInvoice}`}
        element={<CreateInvoice />}
      />
      <Route
        path={`${appRoutes.finance.main}/${appRoutes.finance.payments}`}
        element={<Payments />}
      />
    </>
  ),
  Settings: (
    <Fragment>
      <Route path={`${appRoutes.settings}`} element={<Settings />} />
    </Fragment>
  )
};

export const publicRoutes = (
  <>
    <Route path={appRoutes.home} element={<Landing />} />{" "}
    <Route
      path={appRoutes.packersAndMovers.hyderabad}
      element={<MoversPackersHyderabad />}
    />
    <Route
      path={appRoutes.packersAndMovers.bengaluru}
      element={<MoverPackersBenguluru />}
    />
    <Route path={appRoutes.explore.listings} element={<Listings />} />
    <Route path={appRoutes.explore.main} element={<Explore />} />
    {/* <Route path="*" element={<Navigate to={appRoutes.home} replace />} /> */}
    <Route path={appRoutes.faqs} element={<Faqs />} />
    <Route path={appRoutes.pricing} element={<Pricing />} />
    <Route path={appRoutes.saas} element={<SaaS />} />
    <Route path={appRoutes.marketplace} element={<Marketplace />} />
    <Route path={appRoutes.ewaybill} element={<EwayBill />} />
    <Route path={appRoutes.credit} element={<Credit />} />
    <Route path={appRoutes.erp} element={<ERP />} />
    <Route
      path={appRoutes.inventoryMangement}
      element={<InventoryManagement />}
    />
  </>
);

export const PrivateRoutes = (profileData) => {
  let index = 0;
  const filteredRoutes = Object.entries(menuWiseRoutes)
    .filter(([key]) => {
      // Always keep the Dashboard route
      if (key === "Dashboard") return true;
      // Include routes whose key is present in allowedScModules for ClientSubUser only if scModuleStatus is Active
      if (
        profileData?.user?.role === "ClientSubUser" &&
        profileData?.user?.client?.scModuleStatus?.name === "Active"
      ) {
        return profileData?.user?.allowedScModules.some(
          (module) => module.name === key
        );
      }
      // For CustomerAdmin, include all routes except Dashboard
      if (
        profileData?.user?.role === "CustomerAdmin" &&
        profileData?.user?.client?.scModuleStatus?.name === "Active"
      ) {
        return key !== "Dashboard";
      }
      return key !== "Dashboard" && key === "MarketPlace";
    })
    .map(([key, routeArray]) =>
      // Map through the array of routes and render each Route component with a unique key
      React.Children.toArray(routeArray.props.children).map((route) => (
        <Route
          key={`${key}-${index++}`} // Generate a unique key based on key and index
          path={route.props.path}
          element={route.props.element}
        />
      ))
    );

  const flattenedRoutes = [].concat(...filteredRoutes);

  return (
    <>
      <Route path={appRoutes.home} element={<Landing />} />
      <Route
        path={appRoutes.packersAndMovers.hyderabad}
        element={<MoversPackersHyderabad />}
      />
      <Route
        path={appRoutes.packersAndMovers.bengaluru}
        element={<MoverPackersBenguluru />}
      />
      {flattenedRoutes}
      <Route
        path={`/Order/:orderType/:orderId/:returnType?/:returnId?`}
        element={<OrderBillingPage />}
      />
      <Route
        path={`/:Document/:Page/Gate-Pass/:orderId`}
        element={<GatePass />}
      />
      <Route
        path={`/:Document/:Page/Material-Received-Report/:orderId`}
        element={<MaterialReceivedReports />}
      />
      <Route path={appRoutes.profile.main} element={<Profile />} />
      {/* <Route
        path={appRoutes.profile.changePassword}
        element={<>Change Password</>}
      />
      <Route
        path={appRoutes.profile.helpAndSupport}
        element={<>Help & Support</>}
      />
      <Route path={appRoutes.profile.faqs} element={<>FAQ'S</>} />
      <Route path={appRoutes.profile.tnc} element={<>Terms & Conditions</>} /> */}
      <Route path="*" element={<Navigate to={appRoutes.dashboard} />} />
    </>
  );
};
