import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { HeaderText } from "../../../inventory/components/SKU/SkuDetailsForm";
import { LoadingButton } from "@mui/lab";
import { Close, ExpandMore } from "@mui/icons-material";
import DropDown from "../../../shared/formElements/DropDown";
import { EwayBillForm_labels } from "../../../constants";
import { EwayBillFormKeys } from "../../../constants/formKeys";
import TextInput from "../../../shared/formElements/TextInput";
import DateInput from "../../../shared/formElements/DateInput";
import { useFieldArray, useForm } from "react-hook-form";
import ItemDetailsForm from "./ItemDetailsForm";
import Image from "../../../assets/bru.webp";

const dummyData = [
  {
    image: { thumbUrl: Image },
    brandName: "Instant Bru Coffee",
    skuCode: "BRU-COF-INS- 100G",
    quantity: 45,
    unit: { name: "Pcs" },
    rate: 90,
    gstRegime: 9,
    hsn: "20169060",
    cgst: 405,
    sgst: 405,
    taxable: 5310,
    advol: 5310,
    Nonadval: 5310
  }
];

const AccordianInfo = ({
  title,
  children,
  defaultExpanded = false,
  hideDivider = false
}) => {
  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        {!hideDivider && <Divider sx={{ border: "0.4px solid #B7BEC7" }} />}
      </Grid>
      <Grid item xs={12}>
        <Accordion
          elevation={0}
          defaultExpanded={defaultExpanded}
          sx={{
            "& .MuiAccordionSummary-root.Mui-expanded": {
              minHeight: "45px"
            }
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMore
                sx={{
                  height: "25px",
                  width: "25px",
                  color: "#2773FF"
                }}
              />
            }
            sx={{
              padding: 0,
              border: "none",
              position: "unset",
              minHeight: 0,
              display: "flex",
              justifyContent: "left",
              "& .MuiAccordionSummary-content": {
                margin: 0,
                flexGrow: 1
              },
              "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: 0,
                flexGrow: 1
              }
            }}
          >
            <Typography
              fontWeight={600}
              fontSize={14}
              lineHeight={"16.94px"}
              color={"#1B51B3"}
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              border: "none",
              p: `0px`,
              m: 0
            }}
          >
            {children}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export const CustomFieldRow = ({
  label,
  isDisabled = false,
  children,
  notMandotary = false,
  wrap = false,
  showColon = false,
  labelSize = 6,
  childSize = 6,
  textAlign = "center"
}) => {
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid
        item
        xs={labelSize}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        width="100%"
        textAlign={textAlign}
      >
        <Typography
          fontSize={14}
          fontWeight={600}
          lineHeight={"16.94px"}
          whiteSpace={wrap ? "normal" : "nowrap"}
          color={isDisabled && "#607088"}
          overflow="hidden"
          sx={{ wordBreak: "break-word" }}
        >
          {label?.split("|")?.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {wrap && index < 1 && <br />}
            </React.Fragment>
          ))}
          {!notMandotary && (
            <sup style={{ color: isDisabled ? "#607088" : "#F05454" }}>*</sup>
          )}
          {showColon && <span>{" :"}</span>}
        </Typography>
      </Grid>
      <Grid item xs={childSize} display={"flex"} alignItems={"center"}>
        {children}
      </Grid>
    </Grid>
  );
};

const TransactionDetails = ({ control }) => {
  return (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.supplyType}
          labelSize={5}
          childSize={7}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.supplyType}
            options={[]}
            placeholder={"placeholder"}
            isLoading={false}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.subType}
          labelSize={4}
          childSize={8}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.subType}
            options={[]}
            placeholder={"placeholder"}
            isLoading={false}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.docType}
          labelSize={5}
          childSize={7}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.docType}
            options={[]}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.TransportType}
          labelSize={4}
          childSize={8}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.TransportType}
            options={[]}
            placeholder={"placeholder"}
            isLoading={false}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.docNo}
          labelSize={5}
          childSize={7}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.docNo}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          label={EwayBillForm_labels.docDate}
          labelSize={4}
          childSize={8}
        >
          <DateInput
            control={control}
            name={EwayBillFormKeys.docDate}
            minDate={"2001-01-01"}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
    </Grid>
  );
};

const SupplierForm = ({ control }) => {
  return (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.Name}
          labelSize={2}
          childSize={10}
          notMandotary
          showColon
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.supplierName}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.Gstin}
            labelSize={3.9}
            childSize={8.1}
            showColon
          >
            <TextInput
              control={control}
              name={EwayBillFormKeys.supplierGstin}
              placeholder={"placeholder"}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.State}
            labelSize={3.5}
            childSize={8.5}
            showColon
          >
            <DropDown
              control={control}
              name={EwayBillFormKeys.supplierState1}
              options={[]}
              placeholder={"placeholder"}
            />
          </CustomFieldRow>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <CustomFieldRow
          label={EwayBillForm_labels.shipFrom}
          labelSize={2.4}
          childSize={9.6}
          notMandotary
          showColon
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipFrom1}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow label={""} labelSize={2.4} childSize={9.6} notMandotary>
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipFrom2}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.Place}
          labelSize={2.4}
          childSize={6}
          notMandotary
          showColon
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.supplierPlace}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12} container columnSpacing={2}>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.PIN}
            labelSize={4.8}
            childSize={7.2}
            showColon
          >
            <TextInput
              control={control}
              name={EwayBillFormKeys.supplierPIN}
              placeholder={"placeholder"}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            label={EwayBillForm_labels.State}
            labelSize={3.5}
            childSize={8.5}
            showColon
          >
            <DropDown
              control={control}
              name={EwayBillFormKeys.supplierState1}
              options={[]}
              placeholder={"placeholder"}
            />
          </CustomFieldRow>
        </Grid>
      </Grid>
    </Grid>
  );
};

const RecipientForm = ({ control }) => {
  return (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={12}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.Name}
          labelSize={2}
          childSize={10}
          notMandotary
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientName}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.Gstin}
          labelSize={3.9}
          childSize={8.1}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientGstin}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.State}
          labelSize={3.5}
          childSize={8.5}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.recipientState1}
            options={[]}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12} mt={3}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.shipTo}
          labelSize={2}
          childSize={10}
          notMandotary
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipTo1}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow label={""} labelSize={2} childSize={10} notMandotary>
          <TextInput
            control={control}
            name={EwayBillFormKeys.shipFrom2}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.Place}
          labelSize={2}
          childSize={7}
          notMandotary
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientPlace}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.PIN}
          labelSize={3.9}
          childSize={8.1}
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.recipientPIN}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={6}>
        <CustomFieldRow
          showColon
          label={EwayBillForm_labels.State}
          labelSize={3.5}
          childSize={8.5}
        >
          <DropDown
            control={control}
            name={EwayBillFormKeys.recipientState2}
            placeholder={"placeholder"}
            options={[]}
          />
        </CustomFieldRow>
      </Grid>
    </Grid>
  );
};

const TransportationDetails = ({ control }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.transporterId}
          labelSize={4}
          childSize={7}
          notMandotary
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.transporterId}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.transporterName}
          labelSize={4}
          childSize={7}
          notMandotary
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.transporterName}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
      <Grid item xs={12}>
        <CustomFieldRow
          label={EwayBillForm_labels.distance}
          labelSize={4}
          childSize={7}
          notMandotary
          wrap
        >
          <TextInput
            control={control}
            name={EwayBillFormKeys.distance}
            placeholder={"placeholder"}
          />
        </CustomFieldRow>
      </Grid>
    </Grid>
  );
};

const PartB = ({ control }) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={4}>
      <Grid item xs={5}>
        <DropDown
          name={EwayBillFormKeys.mode}
          control={control}
          label={EwayBillForm_labels.mode}
          options={[]}
          placeholder={"placeholder"}
          isLoading={false}
        />
      </Grid>
      <Grid item xs={4}>
        <DropDown
          name={EwayBillFormKeys.vehicleType}
          control={control}
          label={EwayBillForm_labels.vehicleType}
          options={[]}
          placeholder={"placeholder"}
          isLoading={false}
        />
      </Grid>
      <Grid item xs={5}>
        <TextInput
          name={EwayBillFormKeys.vehicleNo}
          control={control}
          label={EwayBillForm_labels.vehicleNo}
          placeholder={"placeholder"}
        />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={5}>
        <TextInput
          name={EwayBillFormKeys.transportDocNo}
          control={control}
          label={EwayBillForm_labels.transportDocNo}
          placeholder={"placeholder"}
        />
      </Grid>
      <Grid item xs={4}>
        <DateInput
          name={EwayBillFormKeys.transportDocDate}
          control={control}
          minDate={"2000-01-01"}
          placeholder={"placeholder"}
          label={EwayBillForm_labels.transportDocDate}
        />
      </Grid>
    </Grid>
  );
};

const EwayBillForm = ({ hideDrawer }) => {
  const { control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "itemDetails"
  });

  useEffect(() => {
    append(dummyData);
  }, [append]);

  return (
    <Box>
      <Grid
        container
        sx={{
          padding: { xs: "20px", md: "25px" },
          paddingBottom: { xs: "64px", md: "100px" }
        }}
        gap={2}
      >
        <Grid item xs={12}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HeaderText>{"E-Waybill"}</HeaderText>
            <IconButton onClick={hideDrawer}>
              <Close />
            </IconButton>
          </Box>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Accordion
              elevation={0}
              sx={{
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px"
                }
              }}
              defaultExpanded={false}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{
                      height: "25px",
                      width: "25px",
                      color: "#2773FF"
                    }}
                  />
                }
                sx={{
                  padding: 0,
                  border: "none",
                  position: "unset",
                  minHeight: 0,
                  display: "flex",
                  justifyContent: "left",
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                    flexGrow: 1
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                    flexGrow: 1
                  }
                }}
              >
                <Typography
                  fontWeight={500}
                  fontSize={20}
                  lineHeight={"24.2px"}
                  color={"#2773FF"}
                >
                  {"Part A"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  border: "none",
                  p: `0px`,
                  m: 0
                }}
              >
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <AccordianInfo title={"Transaction Details"} hideDivider>
                      <TransactionDetails control={control} />
                    </AccordianInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <AccordianInfo title={"Supplier"}>
                      <SupplierForm control={control} />
                    </AccordianInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <AccordianInfo title={"Recipient"}>
                      <RecipientForm control={control} />
                    </AccordianInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <AccordianInfo title={"Item Details"}>
                      <ItemDetailsForm
                        control={control}
                        fields={fields}
                        remove={remove}
                        append={append}
                      />
                    </AccordianInfo>
                  </Grid>
                  <Grid item xs={12}>
                    <AccordianInfo title={"Transportation Details"}>
                      <TransportationDetails control={control} />
                    </AccordianInfo>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              elevation={0}
              defaultExpanded={false}
              sx={{
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  minHeight: "45px"
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    sx={{
                      height: "25px",
                      width: "25px",
                      color: "#2773FF"
                    }}
                  />
                }
                sx={{
                  padding: 0,
                  border: "none",
                  position: "unset",
                  minHeight: 0,
                  display: "flex",
                  justifyContent: "left",
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                    flexGrow: 1
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                    flexGrow: 1
                  }
                }}
              >
                <Typography
                  fontWeight={500}
                  fontSize={20}
                  lineHeight={"24.2px"}
                  color={"#2773FF"}
                >
                  {"Part B"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  border: "none",
                  p: `0px`,
                  m: 0
                }}
              >
                <PartB control={control} />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ border: "0.4px solid #B7BEC7" }} />
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          padding: {
            xs: "0 16px",
            md: 0
          },
          width: 520,
          position: "fixed",
          bottom: 0
        }}
      >
        <Button
          variant="contained"
          component={LoadingButton}
          loading={false}
          fullWidth
          sx={{
            fontWeight: 500,
            fontSize: 24,
            height: {
              xs: 48,
              md: 81
            },
            borderRadius: { xs: "8px", md: 0 }
          }}
          onClick={() => console.log("sent")}
        >
          {"Save"}
        </Button>
      </Box>
    </Box>
  );
};

export default EwayBillForm;
