import { Box, Container, Grid, IconButton, Typography } from "@mui/material";

import WarexLogo from "../../assets/Warex-white.webp";
import { home_labels } from "../../constants";
import { socialLinks } from "../../shared/layouts/SocialBar";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import appRoutes from "../../constants/appRoutes";
import { searchFormType, StorageKeys } from "../../constants/storage-keys";
import Storage from "../../shared/utils/Storage";

const footer_links = [
  {
    label: "About Us",
    href: "about-us"
  },
  {
    label: "Contact",
    href: "contact-us"
  },
  {
    label: "Industries",
    href: "industries"
  },
  {
    label: "Terms and Conditions",
    href: "terms"
  },
  {
    label: "Privacy Policy",
    href: "privacy"
  },
  {
    label: "FAQs",
    to: appRoutes.faqs
  },
  {
    label: "Simple ERP Software for SMEs",
    to: appRoutes.erp
  },
  {
    label: "Distribution as a Service",
    to: appRoutes.marketplace
  },
  {
    label: "Generate E-Waybills",
    to: appRoutes.ewaybill
  },
  {
    label: "Warehouses in Hyderabad",
    to: appRoutes.explore.main,
    type: "Warehouse",
    city: "Hyderabad"
  },
  {
    label: "Warehouses in Bengaluru",
    to: appRoutes.explore.main,
    type: "Warehouse",
    city: "Bengaluru"
  },
  {
    label: "Easy-to-use Inventory Management",
    to: appRoutes.inventoryMangement
  },
  {
    label: "Credit and Financing for SMEs",
    to: appRoutes.credit
  },
  {
    label: "Supply Chain SAAS",
    to: appRoutes.saas
  },
  {
    label: "Trucks in Hyderabad",
    to: appRoutes.explore.main,
    type: "Truck",
    city: "Hyderabad"
  },
  {
    label: "Trucks in Bengaluru",
    to: appRoutes.explore.main,
    type: "Truck",
    city: "Bengaluru"
  }
];

const mobile_footer_links = [
  {
    label: "Simple ERP Software for SMEs",
    to: appRoutes.erp
  },
  {
    label: "Distribution as a Service",
    to: appRoutes.marketplace
  },
  {
    label: "Easy-to-use Inventory Management",
    to: appRoutes.inventoryMangement
  },
  {
    label: "Credit and Financing for SMEs",
    to: appRoutes.credit
  },
  {
    label: "Generate E-Waybills",
    to: appRoutes.saas
  },
  {
    label: "Supply Chain SAAS",
    to: appRoutes.saas
  },
  {
    label: "Warehouses in Hyderabad",
    to: appRoutes.explore.main,
    type: "Warehouse",
    city: "Hyderabad"
  },
  {
    label: "Warehouses in Bengaluru",
    to: appRoutes.explore.main,
    type: "Warehouse",
    city: "Bengaluru"
  },
  {
    label: "Trucks in Hyderabad",
    to: appRoutes.explore.main,
    type: "Truck",
    city: "Hyderabad"
  },
  {
    label: "Trucks in Bengaluru",
    to: appRoutes.explore.main,
    type: "Truck",
    city: "Bengaluru"
  }
];

const StyledText = styled(Typography)(({ ismobile = false }) => ({
  color: "#B7BEC7",
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "21px",
  letterSpacing: "-0.01em",
  underline: "none",
  marginBottom: ismobile ? "12px" : "20px",
  textDecoration: "none",
  whiteSpace: "nowrap",
  cursor: "pointer"
}));

const Footer = () => {
  const navigate = useNavigate();
  const handleClick = (option) => {
    if (option.to) {
      // Navigate to the specified page
      option?.type === "Warehouse"
        ? Storage.setItem(
            StorageKeys.SEARCH_FORM_TYPE,
            searchFormType.findByWarehouseForm
          )
        : Storage.setItem(
            StorageKeys.SEARCH_FORM_TYPE,
            searchFormType.findByTransportForm
          );
      option?.city === "Hyderabad"
        ? Storage.setItem(StorageKeys.SEARCH_FORM_CITY, "Hyderabad")
        : Storage.setItem(StorageKeys.SEARCH_FORM_CITY, "Bengaluru");
      navigate(option.to);
    } else if (option.href) {
      // Check if the user is already on the landing page
      if (window.location.pathname === "/") {
        // Scroll to the corresponding section on the landing page
        const element = document.getElementById(option.href);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        // Redirect to landing page with section id as query parameter
        const landingPageUrl = `/?id=${option.href}`;
        navigate(landingPageUrl);
      }
    }
  };
  return (
    <Box
      sx={{
        background: "#000000",
        padding: {
          xs: "60px 0 48px 0",
          md: "44px 0 51px 0"
        }
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: {
            xs: "0 20px",
            md: "0 75px"
          }
        }}
      >
        <Grid container>
          <Grid item xs={12} md={3.5}>
            <Box marginBottom={"12px"} marginLeft={-1.9}>
              <img
                src={WarexLogo}
                alt="Warex"
                height={"auto"}
                width={"auto"}
                loading="lazy"
              />
            </Box>
            <Typography
              fontSize={14}
              lineHeight={"21px"}
              color="#B7BEC7"
              fontWeight={300}
              letterSpacing={"-0.01em"}
              maxWidth={252}
            >
              {home_labels.footerSubtext}
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              marginTop={4}
              sx={{
                mb: {
                  xs: 6,
                  md: 0
                }
              }}
            >
              {socialLinks.map(({ icon: Icon, label, to }) => (
                <Box
                  key={label}
                  sx={{
                    mr: 1,
                    "& > button:first-child": {
                      paddingLeft: 0
                    },
                    "& > button > svg": {
                      color: "white",
                      fontSize: 18
                    }
                  }}
                >
                  <IconButton
                    onClick={() => Boolean(to) && window.open(to, "_blank")}
                  >
                    <Icon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            md={5.75}
            sx={{ display: { xs: "none", md: "flex" }, flexDirection: "row" }}
          >
            <Box
              sx={{
                mr: {
                  md: "44px"
                }
              }}
            >
              {footer_links.map(
                (item, index) =>
                  index > 5 &&
                  index < 11 && (
                    <StyledText
                      key={index}
                      display={"flex"}
                      sx={{
                        cursor: "pointer"
                      }}
                      onClick={() => handleClick(item)}
                    >
                      {item.label}
                    </StyledText>
                  )
              )}
            </Box>
            <Box>
              {footer_links.map(
                (item, index) =>
                  index > 10 && (
                    <StyledText
                      key={index}
                      display={"flex"}
                      sx={{
                        cursor: "pointer"
                      }}
                      onClick={() => handleClick(item)}
                    >
                      {item.label}
                    </StyledText>
                  )
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: { xs: "flex", md: "none" }, mb: 1 }}
          >
            <Box>
              {mobile_footer_links.map((item, index) => (
                <StyledText
                  key={index}
                  display={"flex"}
                  ismobile="true"
                  sx={{
                    cursor: "pointer"
                  }}
                  onClick={() => handleClick(item)}
                >
                  {item.label}
                </StyledText>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={2.75} display={"flex"} flexDirection={"row"}>
            <Box
              sx={{
                mr: {
                  xs: "60px",
                  md: "44px"
                }
              }}
            >
              {footer_links.map(
                (item, index) =>
                  index < 3 && (
                    <StyledText
                      key={index}
                      display={"flex"}
                      sx={{
                        cursor: "pointer"
                      }}
                      onClick={() => handleClick(item)}
                    >
                      {item.label}
                    </StyledText>
                  )
              )}
            </Box>
            <Box>
              {footer_links.map(
                (item, index) =>
                  index > 2 &&
                  index < 6 && (
                    <StyledText
                      key={index}
                      display={"flex"}
                      sx={{
                        cursor: "pointer"
                      }}
                      onClick={() => handleClick(item)}
                    >
                      {item.label}
                    </StyledText>
                  )
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
