import React, { useRef, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { isMobile } from "react-device-detect";
import { SectionHeader } from "./SolutionsSection";
import { home_labels } from "../../constants";
import ProductFeatureItem from "./ProductFeatureItem";
import Explore from "../../assets/Explore.webp";
import RFQ from "../../assets/RFQ.webp";
import Quote from "../../assets/Quote.webp";
import compareQuotes from "../../assets/compareQuotes.webp";
import orderManagement from "../../assets/orderManagement.webp";
import Ship from "../../assets/Ship.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";

const product = [
  {
    label: home_labels.Explore,
    subtext: home_labels.exploreSubtext,
    image: Explore,
    padding: "92px",
    id: "Explore"
  },
  {
    label: home_labels.RFQ,
    subtext: home_labels.rfqSubText,
    image: RFQ,
    padding: "92px",
    id: "RFQ"
  },
  {
    label: home_labels.Quote,
    subtext: home_labels.QuoteSubtext,
    image: Quote,
    padding: "13.75px",
    id: "Quote"
  },
  {
    label: home_labels.compareQuotes,
    subtext: home_labels.compareQuotesSubtext,
    image: compareQuotes,
    padding: "13.75px",
    id: "compareQuotes"
  },
  {
    label: home_labels.orderManagement,
    subtext: home_labels.orderManagementSubtext,
    image: orderManagement,
    padding: "13.75px",
    id: "orderManagement"
  },
  {
    label: home_labels.ship,
    subtext: home_labels.shipSubTextonly,
    image: Ship,
    padding: "13.75px",
    id: "Ship"
  }
];

const MarketplaceSection = () => {
  const swiperRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const targetElement = containerRef.current; // Store current value of containerRef

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
            // When 40% or more of the swiper is visible, start autoplay
            if (swiperRef.current) swiperRef.current.autoplay.start();
          } else {
            // When less than 40% is visible, stop autoplay
            if (swiperRef.current) swiperRef.current.autoplay.stop();
          }
        });
      },
      {
        threshold: 0.4 // Trigger when 40% of the Swiper is in view
      }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement); // Clean up the observer with the stored targetElement
      }
    };
  }, []);

  return (
    <Box
      id="market-place"
      ref={containerRef}
      display="flex"
      flexDirection="column"
      textAlign="center"
      sx={{
        background: "#F7F8FA",
        mt: {
          xs: "20px",
          md: "40px"
        },
        padding: {
          xs: "0 20px",
          md: "5px 65px"
        },
        gap: { xs: 0, md: 2 }
      }}
    >
      <Box
        display="flex"
        width="100%"
        sx={{
          position: "relative",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box>
          <SectionHeader
            title={home_labels.WarexMarketplace}
            props={{ fontWeight: 700 }}
          />
        </Box>
        {/* <Box
          sx={{
            position: { md: "absolute" },
            right: { md: 0 },
            paddingTop: { xs: "20px", md: 0 }
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { xs: "80px", md: "150px" },
              padding: { xs: "0px" },
              height: { xs: "30px", md: "60px" },
              fontSize: { xs: "10px", md: "17px" },
              lineHeight: { xs: "12px", md: "20px" },
              borderRadius: "4px",
              border: "1px 0px 0px 0px",
              opacity: "0.99px"
            }}
            onClick={() => navigate(appRoutes.marketplace)}
          >
            {"Know More"}
          </Button>
        </Box> */}
      </Box>
      <Box>
        <Swiper
          slidesPerView={isMobile ? 1 : 3}
          spaceBetween={30}
          speed={1000}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          loop={true}
          pagination={{
            clickable: true
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper-1"
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          {product.map((item, index) => (
            <SwiperSlide key={item.label}>
              <Grid item xs={12} md={4} style={{ marginRight: "16px" }}>
                <ProductFeatureItem {...item} />
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default MarketplaceSection;
