import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { ItemDetailsCard } from "./ItemDetailsCard";
import { AddCircleOutline } from "@mui/icons-material";
import { CustomFieldRow } from "./EwayBillForm";
import TextInput from "../../../shared/formElements/TextInput";
import DropDown from "../../../shared/formElements/DropDown";

const getRows = (rows, control) => {
  return rows.map((row, index) => ({
    ...row,
    index: index,
    rate: row?.rate ? (
      parseFloat(row?.rate).toFixed(2)
    ) : (
      <TextInput
        control={control}
        name={`itemDetails.${index}.rate`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF"
        }}
        placeholder={"rate"}
      />
    ),
    quantity: row?.quantity ? (
      parseFloat(row?.quantity).toFixed(2)
    ) : (
      <TextInput
        control={control}
        name={`itemDetails.${index}.quantity`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF"
        }}
        placeholder={"Qty"}
      />
    ),
    cgst: parseFloat(row?.cgst).toFixed(2),
    sgst: parseFloat(row?.sgst).toFixed(2),
    taxable: parseFloat(row?.taxable).toFixed(2),
    advol: row?.advol ? (
      parseFloat(row?.advol).toFixed(2)
    ) : (
      <TextInput
        control={control}
        name={`itemDetails.${index}.advol`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF"
        }}
        placeholder={"Cess Advol %"}
      />
    ),
    Nonadval: row?.Nonadval ? (
      parseFloat(row?.Nonadval).toFixed(2)
    ) : (
      <TextInput
        control={control}
        name={`itemDetails.${index}.Nonadval`}
        type="number"
        sx={{
          maxHeight: { xs: "30px", md: "44px" },
          border: "1px solid #2773FF"
        }}
        placeholder={"Cess nonAdvol rate"}
      />
    ),
    item: !row.item && (
      <DropDown
        control={control}
        name={`itemDetails.${index}.item`}
        options={[]}
        placeholder={"Select SKU"}
        allowSearch
      />
    )
  }));
};

const ItemDetailsForm = ({ fields = [], control, append, remove }) => {
  const onAddClick = () => {
    append({ sku: "" });
  };
  return (
    <Grid container spacing={1} padding={"20px 20px 0px 30px"}>
      {fields.length < 1 ? (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          Add Items
        </Grid>
      ) : (
        <Box
          className="custom-mobile-table"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px"
          }}
        >
          {getRows(fields, control)?.map((item, index) => (
            <Box
              key={index}
              sx={{
                border: "0.4px solid #B7BEC7",
                boxShadow: "0px 8px 24px rgba(21, 21, 22, 0.1)",
                borderRadius: "8px",
                padding: "5px 16px",
                position: "relative"
              }}
            >
              <ItemDetailsCard row={item} remove={remove} />
            </Box>
          ))}
        </Box>
      )}
      <Grid container my={1}>
        <Grid item xs={12}>
          <IconButton onClick={onAddClick}>
            <AddCircleOutline sx={{ color: "#2773FF", fontSize: 28 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container ml={1} rowSpacing={3} columnSpacing={1}>
        <Grid item xs={12}>
          <Typography
            fontWeight={600}
            fontSize={14}
            lineHeight={"16.94px"}
            color={"#1B51B3"}
          >
            {"Totals"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Taxable Amount"}
            labelSize={6}
            childSize={6}
            showColon
            notMandotary
          ></CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"CGST Amount"}
            labelSize={7}
            childSize={5}
            showColon
            notMandotary
          ></CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"SGST Amount"}
            labelSize={7}
            childSize={5}
            showColon
            notMandotary
          ></CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Cess|Adv Amount"}
            labelSize={7}
            childSize={5}
            showColon
            wrap
            notMandotary
          ></CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Cess NonAdv|Amount"}
            labelSize={7}
            childSize={5}
            showColon
            wrap
            notMandotary
          ></CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Others"}
            labelSize={4}
            childSize={7}
            showColon
            notMandotary
          >
            <TextInput
              control={control}
              name={"others"}
              placeholder={"1234"}
              sx={{ border: "1px solid #2773FF" }}
            />
          </CustomFieldRow>
        </Grid>
        <Grid item xs={6}>
          <CustomFieldRow
            textAlign={"left"}
            label={"Total Invoice|Amount"}
            labelSize={10}
            childSize={2}
            showColon
            wrap
          ></CustomFieldRow>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ItemDetailsForm;
